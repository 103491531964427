export function loadInovestorScript(locale: string): void {
  const localeMap: Record<string, string> = {
    'en-CA': 'en',
    'fr-CA': 'fr'
  }
  const localeParam = localeMap[locale] ?? 'en'
  const scriptNode = document.getElementById('inovestor-script')

  if (scriptNode) {
    scriptNode.remove()
  }

  const script = document.createElement('script')
  script.addEventListener('load', () => {
    window.ino_pushData()
  })
  script.setAttribute('id', 'inovestor-script')
  script.setAttribute('src', `https://dfc.inovestor.com/KPT/QuoteEngine.aspx?language=${localeParam}&autoload=false`)
  script.setAttribute('defer', 'true')
  document.head.appendChild(script)
}
